
.slick-prev,
 .slick-next {
  display: none !important;
  
}
.slick-prev::before,
.slick-next::before {
  display: none;
}
/* .slick-slide {
  margin-right: 0px;
  padding: 0px;
} */


/* ///////////////// *******************************/

/* .slick-slide > div {
  margin: 0 0px;
} */

/* .FirstSlider-slick-slide {
  margin-right: 0px;
  padding: 0px;
} */


.MainSliderBox{
    /* border:1px solid red; */
    height:500px;
    width:100%;
    margin-top: 30px;
    
}

.mainDiv{
    margin: auto;
    width:100%;
    height:500px;
    padding: 10px;
   
    /* border:1px solid black */
}
