


.App {
  /* text-align: center; */
  /* background-color:#222222; */
  font-family: Nunito,sans-serif;
  /* background-color: #000814; */
   /* background-color: #e0e4ec; */

/* background-color: rgb(246, 245, 245); */
}



