

.Slide2 .slick-slide > div {
  margin: 0 15px;
}






.mainSubjectDIv{
    /* border:1px solid white; */
    height:auto;
    margin-top: 100px;
    overflow-x: hidden;
}
.cardOne:hover {
    -webkit-transform: translate(10px);
    transform: translate(-10px);
    -webkit-transition: all 0.5s;
    transition: all 0.8s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
.card:hover {
    -webkit-transform: translate(10px);
    transform: translate(10px);
    -webkit-transition: all 0.5s;
    transition: all 0.8s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .cardTwo:hover {
    -webkit-transform: translate(10px);
    transform: translateY(-10px);
    -webkit-transition: all 0.5s;
    transition: all 0.8s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }