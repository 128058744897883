body {
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mainscreen {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #dfdbe5;
  background-image: url("https://wallpaperaccess.com/full/3063067.png");
  color: black;
}

.card {
  width: 60rem;
  margin: auto;
  background: white;
  position: center;
  align-self: center;
  top: 50rem;
  border-radius: 1.5rem;
  box-shadow: 4px 3px 20px 1px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: row;
}

.leftside {
  background: #030303;
  width: 25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.product {
  object-fit: cover;
  width: 20em;
  height: 20em;
  border-radius: 100%;
}

.rightside {
  background-color: #ffffff;
  width: 35rem;
  border-bottom-right-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  padding: 1rem 2rem 3rem 3rem;
}

p {
  display: block;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0.8rem 0;
}

.button {
  background: linear-gradient(135deg, #753370 0%, #298096 100%);
  padding: 15px;
  border: none;
  border-radius: 50px;
  color: white;
  font-weight: 400;
  font-size: 1.2rem;
  margin-top: 10px;
  width: 100%;
  letter-spacing: 0.11rem;
  outline: none;
}

.button:hover {
  transform: scale(1.05) translateY(-3px);
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 1000px) {
  .card {
    flex-direction: column;
    width: auto;
  }

  .leftside {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 0;
  }

  .rightside {
    width: auto;
    border-bottom-left-radius: 1.5rem;
    padding: 0.5rem 3rem 3rem 2rem;
    border-radius: 0;
  }
}
